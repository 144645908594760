import React from "react";
import styled from "styled-components";

import { SpinningRays, LogoCircle } from "zt-web";

import mountainslandscape from "../../images/mountainslandscape.png";
import kayak_logo from "../../images/kayak_logo.png";

const Heading = styled.h2`
	color: #fff;
	font-size: 30px;
	margin: 0 100px;
	max-width: 700px;
`;

export default function Home() {
	return (
		<>
			<header className="App-header">
				<SpinningRays />
				<LogoCircle>
					<div className="App-logo-circle-zen">ZEN</div>
					<div className="App-logo-circle-trails">Trails</div>
				</LogoCircle>
			</header>

			<img
				src={mountainslandscape}
				alt=""
				style={{
					position: "relative",
					zIndex: 20,
					height: "100px",
				}}
			/>
			<main className="App-main">
				<Heading className="App-mission-text">
					Our mission is to unite people through a collective love for
					experiencing nature.
				</Heading>
				<h3>Download our first mobile app, ZEN Kayak:</h3>
				<a href="https://zenkayak.com">
					<img src={kayak_logo} alt="" style={{ height: "100px" }} />
				</a>
			</main>
		</>
	);
}
