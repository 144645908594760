import React from "react";
import styled from "styled-components";
import { gql, useMutation } from "@apollo/client";

import { ContactUsForm } from "zt-web";

const CONTACT_US = gql`
	mutation contactUs(
		$name: String!
		$email: String!
		$message: String!
		$subject: String!
	) {
		contactUs(
			name: $name
			email: $email
			message: $message
			subject: $subject
		) {
			success
			error
		}
	}
`;

const Container = styled.section``;

export default function ContactUs() {
	const [contactUs, { data, loading, error }] = useMutation(CONTACT_US);

	return (
		<Container>
			<ContactUsForm
				success={data && data.contactUs && data.contactUs.success}
				error={error}
				disabled={loading}
				onSubmit={(e) => {
					e.preventDefault();

					let elements = e.target.elements;

					contactUs({
						variables: {
							name: elements.name.value,
							email: elements.email.value,
							message: elements.message.value,
							subject: "ZT_CONTACT",
						},
					});
					return false;
				}}
			/>
		</Container>
	);
}
